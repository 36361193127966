<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(tanggalMulaiKontrak)="item">
                    <b-row class="d-flex">
                      <b-col class="text-left" md="5">
                        {{ moment(item.item.tanggalMulaiKontrak).format("LL") }}
                      </b-col>
                      <b-col md="2">s/d</b-col>
                      <b-col class="text-right" md="5">
                        {{
                          moment(item.item.tanggalSelesaiKontrak).format("LL")
                        }}
                      </b-col>
                    </b-row>
                  </template>
                  <template #cell(statusPerjanjianAktif)="item">
                    <p v-if="item.item.statusPerjanjianAktif == 0">Aktif</p>
                    <p v-if="item.item.statusPerjanjianAktif == 1">
                      Habis Kontrak
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 2">
                      Diberhentikan
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 3">Resign</p>
                    <p v-if="item.item.statusPerjanjianAktif == 4">
                      Diperpanjang
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 5">
                      Mutasi Jabatan
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 6">Meninggal</p>
                    <p v-if="item.item.statusPerjanjianAktif == 7">
                      Tanpa Keterangan
                    </p>
                    <p v-if="item.item.statusPerjanjianAktif == 8">
                      Belum Aktif
                    </p>
                  </template>
                  <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="$store.state.table.tooltipEdit"
                      v-b-modal.modal-edit
                      @click="dataSurat = item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="$store.state.table.tooltipHapus"
                      v-b-modal.modal-detail
                      @click="dataSurat = item.item, detail = !detail"
                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- <modal-input @alertFromChild="triggerAlert($event)" /> -->
    <modalEdit
      :dataSurat="dataSurat"
      :karyawan="karyawan"
      @alertFromChild="triggerAlert($event)"
    />
    <modalDetail
      :dataSurat="dataSurat" :detail="detail"
      @alertFromChild="triggerAlert($event)"
    />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import moment from "moment";
import "moment/locale/id";
// import modalInput from "../../../component/hcms/karyawan/perjanjian/modalInput.vue";
import modalEdit from "./modalEdit.vue";
import modalDetail from "./modalDetail.vue";

export default {
  components: {
    // modalInput,
    modalEdit,
    modalDetail,
  },
  props: ["data", "change", "karyawan"],
  data() {
    return {
      moment,
      showing: false,
      detail: false,
      variant: "success",
      msg: "",
      dataSurat: "",
      items: [
        {
          no: "-",
          nmAsuransi: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nomorSurat",
          label: "Nomor Surat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggalMulaiKontrak",
          label: "Periode Kontrak",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "masaKontrak",
          label: "Durasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "statusPerjanjianAktif",
          label: "Status Kontrak",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   class: "table-option-2 text-center",
        // },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      this.tableBusy = true;
      let listPerjanjian = await axios.get(
        ipBackend +
          "suratPerjanjianKerja/listSuratPerjanjianByDataKaryawanId/" +
          this.data.dataKaryawanId
      );
      this.items = listPerjanjian.data.data;
      for (let i = 0; i < this.items.length; i++) {
        for (let j = 0; j < this.karyawan.length; j++) {
          if (this.karyawan[j].dataKaryawanId == this.items[i].dataKaryawanId) {
            this.items[i].namaKaryawan = this.karyawan[j].namaKaryawan;
            this.items[i].namaPosisi = this.karyawan[j].namaPosisi;
            this.items[i].namaDivisi = this.karyawan[j].namaDivisi;
            this.items[i].statusKerjaKaryawan = this.karyawan[j].statusKerjaKaryawan;
          }
        }
        let x = this.items[i];
        x.no = i + 1;
      }
      console.log(this.items, "iki");
      this.totalRows = this.items.length;
      this.tableBusy = false;
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getDatas();
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
  },
  watch: {
    data: function (newVal, oldVal) {
      console.log(newVal, "per");
      if (newVal != oldVal) {
        this.getDatas();
      }
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-tabs content-class="mt-3" align="center" fill>
          <b-tab title="Perjanjian" active>
            <perjanjian :data="data" :karyawan="karyawan" :change="change" />
          </b-tab>
          <b-tab title="Perintah"
            ><perintah :data="data" :karyawan="karyawan" :change="change" />
          </b-tab>
          <!-- <b-tab title="Resign">
            <resign :data="data" :karyawan="karyawan" :change="change"
          /></b-tab> -->
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Perjanjian from "./Perjanjian.vue";
import Perintah from "./Perintah.vue";
import Resign from "./Resign.vue";
export default {
  components: { Perjanjian, Perintah, Resign },
  props: ["data", "karyawan", "change"],
  data() {
    return {};
  },
  watch: {
    change: function (val) {
      console.log(val);
    },
  },
};
</script>

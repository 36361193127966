<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <b-form-group label="Karyawan" label-cols-md="3">
            <multiselect
              v-model="karyawanId"
              :options="karyawan"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaKaryawan"
              track-by="dataKaryawanId"
              @input="getData()"
              placeholder="-- Pilih Posisi --"
            ></multiselect>
          </b-form-group> </b-card
      ></b-col>
    </b-row>
    <b-row v-if="data">
      <b-col cols="12" md="12" lg="12">
        <b-card>
          <b-tabs content-class="mt-3" align="center" fill>
            <b-tab title="Profil" active>
              <tab-profil
                :data="data"
                :asuransi="asuransi"
                @alertFromChild="triggerAlert($event)"
              />
            </b-tab>
            <b-tab title="Absensi">
              <tab-absensi :data="data" />
            </b-tab>
            <!-- <b-tab title="Penilaian">
              <tab-penilaian />
            </b-tab> -->
            <b-tab title="Pelanggaran">
              <tab-pelanggaran :data="data" @change="change = !change" />
            </b-tab>
            <b-tab title="Peringatan">
              <tab-peringatan :data="data" :change="change" />
            </b-tab>
            <b-tab title="Kontrak">
              <tab-riwayat :data="data" :karyawan="karyawan" :change="change" />
            </b-tab>
            <b-tab title="Penggajian">
              <tab-penggajian :data="data" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import tabProfil from "@/component/hcms/karyawan/tabProfil.vue";
import tabAbsensi from "@/component/hcms/karyawan/tabAbsensi.vue";
import tabPenilaian from "@/component/hcms/karyawan/tabPenilaian.vue";
import tabPelanggaran from "@/component/hcms/karyawan/tabPelanggaran.vue";
import tabPeringatan from "@/component/hcms/karyawan/tabPeringatan.vue";
import tabPenggajian from "@/component/hcms/karyawan/tabPenggajian.vue";
import tabRiwayat from "./Surat/index.vue"
export default {
  props: [],
  components: {
    tabProfil,
    tabAbsensi,
    tabPenilaian,
    tabPelanggaran,
    tabPeringatan,
    tabPenggajian,
    tabRiwayat,
    Multiselect,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      data: "",
      karyawan: [],
      asuransi: [],
      karyawanId: "",
      change: false,
    };
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        // console.log(this.data, "ini datanya");
      }
    },
  },
  mounted() {
    this.getKaryawan();
  },
  methods: {
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
    async getKaryawan() {
      let karyawan = await axios.get(ipBackend + "dataKaryawan/list");
      console.log(karyawan.data.data);
      this.karyawan = karyawan.data.data;

      let asuransi = await axios.get(ipBackend + "masterAsuransi/list");

      this.asuransi = asuransi.data.data;
    },
    async getData() {
      this.data = this.karyawanId;
      this.change = !this.change
    },
  },
};
</script>
